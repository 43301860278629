@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
body {
  margin: 0;
  padding: 0 0 0 0;
  font-family: sans-serif;  
}
.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 80px;  
}

/* DEMO STYLES */
.nav {
  padding: 30px 0;
  
}
.nav a {
  display: inline-block;
  width: 100px;
  text-align: center;
  margin: 0 15px;
  padding: 10px;
  border: 1px rgba(255,255,255,0.5) solid;
  transition: all 0.3s linear;
  text-decoration: none;
  color: white;
}
.nav a.active {
  background-color: #61DAFB;
  color: #222;
}
section {
  padding: 20px 0;
  max-width: 600px;
  margin: 0 auto;
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}
.slide-enter.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
    opacity: 1;
    transform: scale(1) translateY(0);
    
}
.slide-exit.slide-exit-active {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    transition: opacity 1500ms linear, transform 1500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}

/* Card */
.card-container {
  position: relative;
  max-width: 226px;
  margin: 0 auto;
}

.card {
  border: 3px #f3f3f3 solid;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  cursor: pointer;
  background-color: #f3f3f3;
  padding: 10px;
  max-width: 220px;
  text-align: left;
  margin: 0 auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.card:hover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.card img {
  max-width: calc(100%);
  margin: 0 auto;
  display: block;
}
.card .index {
  
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 10px;
}
.card .price {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
}
.card .details {
  position: relative;
}
.card .features {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card .features li {
  padding-left: 24px;
  margin-right: 10px;
  display: inline-block;
}
.card .features li span {
  display: none;
}
.card .features li.icon-bed {
  background: url(/static/media/bed-left-side.cb488666.svg) left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.card .features li.icon-bath {
  background: url(/static/media/bathtub.b04eabf7.svg);
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.card .features li.icon-car {
  background: url(/static/media/car-compact.6c448f8d.svg);
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.snip1226 {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.snip1226 * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.snip1226 li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1.5em;
  overflow: hidden;
}

.snip1226 a {
  padding: 0.3em 0;  
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}

.snip1226 a:before,
.snip1226 a:after {
  position: absolute;
  transition: all 0.35s ease;
}

.snip1226 a:before {
  bottom: 100%;
  display: block;
  height: 3px;
  width: 100%;
  content: "";
  color: blue;
  background-color: #e67e22;
}

.snip1226 a:after {
  padding: 0.3em 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: red;
  white-space: nowrap;
}

.snip1226 li:hover a,
.snip1226 .current a {
  transform: translateY(100%);
}

/* Demo purposes only */
body {
  /*background-color: gainsboro;*/

  background: linear-gradient(90deg, rgb(47, 31, 219) 0%, rgb(3, 3, 19) 35%, rgb(163, 55, 52) 100%);
}

h1,h2,h3,h4,a {
  color: white;
}
.jumbotron  {
  background-color: beige;
}

.jumbotron a {
  color: darkred;
}

li {
  font-style: italic;
  font-weight: bold;
}

.copywrite {
  padding-top: .1rem;
  color: white;
}

.myName {
  padding-top: 1rem;
}
